<template>
  <Swiper class="mb-4" :spaceBetween="mobile || isMobile ? null : 50" :centeredSlides="true" :centered-slides="true"
    :centered-slides-bounds="false" :slides-per-view="mobile || isMobile ? 1 : 'auto'"
    :cssMode="mobile || isMobile ? true : false" :pagination="{
      clickable: true,
      dynamicBullets: true,
    }" :autoplay="{ delay: 5000, disableOnInteraction: false }" :loop="true" :modules="modules" :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }">
    <v-btn class="swiper-button-prev swiper-button-custom" size="large" icon="mdi-chevron-left" />
    <v-skeleton-loader v-if="slideLoading" height="100%" type="image"></v-skeleton-loader>
    <SwiperSlide v-else-if="!slideLoading" v-for="(slide, i) in slides" class="" :key="i">
      <v-card class="card-carousel" rounded="md">
        <v-img @contextmenu.prevent class="img-carousel" cover :alt="slide.key" :src="slide.url"
          :lazy-src="slide.url" />
      </v-card>
    </SwiperSlide>
    <v-btn class="swiper-button-next swiper-button-custom" size="large" icon="mdi-chevron-right" />
  </Swiper>

  <v-container fluid>
    <!-- PAST EVENTS -->
    <v-sheet v-if="pastEvents.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="pastEvents.data.length" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.past.title") }}
      </h6>
      <p class="text-subtitle-2">
        {{ $t("home.carousel.past.subtitle") }}
      </p>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-slide-group-item>
          <template v-for="(product, index) in pastEvents.data" :key="index">
            <event-card :product="product"></event-card>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <div class="text-right">
        <v-btn v-if="this.pastEvents.count > 10" to="/discovery/past-events" class="text-primary text-subtitle-2"
          append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- KONSER TERKINI -->
    <v-sheet v-if="concertProducts.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="concertProducts.data.length" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.concert.title") }}
      </h6>
      <p class="text-subtitle-2">
        {{ $t("home.carousel.concert.subtitle") }}
      </p>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-slide-group-item>
          <template v-for="(product, index) in concertProducts.data" :key="index">
            <event-card :product="product"></event-card>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <div class="text-right">
        <v-btn v-if="this.concertProducts.count > 10" to="/discovery/music-concert" class="text-primary text-subtitle-2"
          append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- HEALING TERKINI -->
    <v-sheet v-if="attractionProducts.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="attractionProducts.data.length" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.attraction.title") }}
      </h6>
      <p class="text-subtitle-2">
        {{ $t("home.carousel.attraction.subtitle") }}
      </p>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-slide-group-item>
          <template v-for="(product, index) in attractionProducts.data" :key="index">
            <non-event-card :product="product"></non-event-card>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <div class="text-right">
        <v-btn v-if="this.attractionProducts.count > 10" to="/discovery/attraction" class="text-primary text-subtitle-2"
          append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- UPCOMING EVENT -->
    <v-sheet v-if="upcomingProducts.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="upcomingProducts?.count" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.upcoming.title") }}
      </h6>
      <p class="text-subtitle-2">{{ $t("home.carousel.upcoming.subtitle") }}</p>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-slide-group-item>
          <template v-for="(product, index) in upcomingProducts.data" :key="index">
            <event-card :is_upcoming="true" :product="product"></event-card>
            <non-event-card :is_upcoming="true" :product="product"></non-event-card>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <div class="text-right">
        <v-btn v-if="upcomingProducts.count > 10" to="/discovery/upcoming" class="text-primary text-subtitle-2"
          append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- TIKET HEALING -->
    <v-sheet v-if="healingProducts.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="healingProducts.data.length || selection != 'Semua'" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.healing.title") }}
      </h6>
      <p class="text-subtitle-2">
        {{ $t("home.carousel.healing.subtitle") }}
      </p>

      <v-slide-group v-model="selection" mandatory show-arrows>
        <v-slide-group-item v-for="(tag, index) in tags" :key="tag" :value="index" v-slot="{ isSelected, toggle }">
          <v-btn variant="tonal" class="ma-2 text-capitalize" :class="isSelected
            ? 'bg-primary-container-dark text-white'
            : 'bg-primary-95 text-primary-container-dark'
            " @click="
              getHealingProduct({
                page: 1,
                pageSize: this.pageSize,
                city: tag,
              });
            toggle();
            ">
            {{ tag }}
          </v-btn>
        </v-slide-group-item>
      </v-slide-group>

      <div v-if="healingProducts.data.length > 0">
        <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
          prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
          show-arrows="desktop">
          <v-slide-group-item>
            <template v-for="(product, index) in healingProducts.data" :key="index">
              <non-event-card :product="product"></non-event-card>
            </template>
          </v-slide-group-item>
        </v-slide-group>

        <div class="text-right">
          <v-btn v-if="healingProducts.count > 10" to="/discovery/healing-sejenak" class="text-primary text-subtitle-2"
            append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
          </v-btn>
        </div>
      </div>

      <div class="text-center py-5" v-else>
        <p class="text-center text-h6 font-weight-bold">
          Tiket Belum Tersedia Untuk Lokasi {{ tags[selection] }}
        </p>
      </div>
    </v-sheet>

    <!-- TIKET RACE -->
    <v-sheet v-if="raceProducts.loading" class="mx-auto bg-color" max-width="90vw">
      <v-skeleton-loader class="bg-color" type="list-item-two-line"></v-skeleton-loader>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-skeleton-loader v-for="index in 10" :key="index" class="mx-4 border bg-color" width="300"
          type="image, list-item-three-line"></v-skeleton-loader>
      </v-slide-group>
    </v-sheet>

    <v-sheet v-else-if="raceProducts.data.length" class="mx-auto bg-color" max-width="90vw">
      <h6 class="text-h6 font-weight-bold">
        {{ $t("home.carousel.race.title") }}
      </h6>
      <p class="text-subtitle-2">
        {{ $t("home.carousel.race.subtitle") }}
      </p>

      <v-slide-group v-model="model" class="pa-3" selected-class="bg-success"
        prev-icon="mdi-chevron-left-circle-outline" next-icon="mdi-chevron-right-circle-outline" center-active
        show-arrows="desktop">
        <v-slide-group-item>
          <template v-for="(product, index) in raceProducts.data" :key="index">
            <event-card :product="product"></event-card>
          </template>
        </v-slide-group-item>
      </v-slide-group>

      <div class="text-right">
        <!-- FIXME -->
        <v-btn v-show="false" v-if="this.raceProducts.count > 10" class="text-primary text-subtitle-2"
          append-icon="mdi-arrow-right" variant="text">{{ $t("home.button.see-more") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-container>

  <v-row class="mt-6" no-gutters align="center" justify="center">
    <v-col cols="12">
      <p class="text-center text-h6 font-weight-bold">
        {{ $t("home.info-1.title") }}
      </p>
    </v-col>
  </v-row>

  <div class="mb-6 flex-wrap d-flex justify-space-evenly align-center">
    <div class="text-center" :style="{ 'max-width': '250px' }" v-for="({ title, subtitle, src }, i) in sure" :key="i">
      <v-img @contextmenu.prevent class="d-inline-block" :src="src" :lazy-src="src" width="200" height="200"></v-img>
      <p class="text-body-2 font-weight-bold">{{ title }}</p>
      <p class="text-subtitle-2 font-weight-regular">{{ subtitle }}</p>
    </div>
  </div>

  <div class="bg-tertiary-container-dark py-15 d-flex align-center justify-space-around flex-wrap">
    <v-sheet max-width="250" class="bg-tertiary-container-dark ma-2">
      <p class="text-h5 font-weight-bold">{{ $t("home.info-2.title") }}</p>
      <p>
        {{ $t("home.info-2.content") }}
      </p>
    </v-sheet>

    <div class="d-flex justify-end ma-2">
      <v-btn @click="goToURL(url.web_seller + 'signup')" color="white" class="text-bg-background text-none">{{
        $t("home.button.register-ticket") }}</v-btn>
    </div>
  </div>

  <div class="d-flex flex-wrap justify-space-evenly align-center py-15 bg-benefit">
    <v-sheet max-width="250" class="bg-benefit">
      <p class="mb-3 text-h5 font-weight-bold">
        {{ $t("home.info-3.title") }}
      </p>
      <v-btn @click="goToURL(url.web_seller + 'signup')" class="text-capitalize" color="primary-container-dark">{{
        $t("home.button.register") }}</v-btn>
    </v-sheet>

    <v-list class="bg-benefit">
      <v-list-item v-for="(item, i) in sellerBenefit()" :key="i">
        <template v-slot:prepend>
          <v-icon color="primary" icon="mdi-check-circle"></v-icon>
        </template>
        <v-list-item-title>
          <div class="text-wrap">{{ item }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>

  <div class="d-flex flex-wrap justify-space-evenly align-center justify-center py-15 px-6">
    <v-spacer></v-spacer>

    <v-sheet class="bg-main-background" max-width="400">
      <p class="text-h5 font-weight-bold">
        {{ $t("home.newsletter.title") }}
      </p>
      <p class="text-wrap">{{ $t("home.newsletter.subtitle") }}</p>
    </v-sheet>

    <v-spacer></v-spacer>

    <v-sheet class="bg-main-background" max-width="1000" width="500">
      <v-text-field label="Email" hide-details color="primary" variant="outlined" type="email"
        v-model="newsLetter.email" :rules="newsLetter.rules" validate-on="blur" :style="{ 'max-width': '600px' }"
        density="comfortable" @keypress.prevent.enter="subscribeNewsLetter(newsLetter.email, isValid)">
        <template v-slot:append="{ isValid }">
          <v-btn :loading="newsLetter.loading" @click="subscribeNewsLetter(newsLetter.email, isValid)" color="primary"
            class="text-capitalize h-100">{{ $t("home.button.register") }}</v-btn>
        </template>
      </v-text-field>
    </v-sheet>

    <v-spacer></v-spacer>
  </div>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        {{ $t("home.button.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import EventCard from "@/components/Cards/EventCard.vue";
import { CATEGORY } from "@/config/category.js";
import NonEventCard from "@/components/Cards/NonEventCard.vue";
import NotSure1 from "@/assets/01_ilustration_segment_not_sure.svg";
import NotSure2 from "@/assets/02_ilustration_segment_not_sure.svg";
import NotSure3 from "@/assets/03_ilustration_segment_not_sure.svg";
import isEmail from "validator/lib/isEmail";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  EffectCreative,
  Autoplay,
} from "swiper";
import { useSwiper } from "swiper/vue";
import { utcToLocale } from "@/utils/dateFormatter";
import { useDisplay } from "vuetify/lib/framework.mjs";

export default {
  name: "HomeView",

  components: {
    Swiper,
    SwiperSlide,
    NonEventCard,
    EventCard,
  },

  setup() {
    const swiper = useSwiper();
    const { mobile } = useDisplay()
    // const modules = mobile ? [Pagination, Navigation, EffectCreative, Autoplay] : [EffectCoverflow, Pagination, Navigation, EffectCreative, Autoplay];
    return {
      swiper,
      mobile,
    };
  },

  computed: {
    isMobile() {
      const { mobile } = useDisplay()
      return mobile.value
    },

    modules() {
      const modules = this.isMobile ? [Pagination, Navigation, EffectCreative, Autoplay] : [EffectCoverflow, Pagination, Navigation, EffectCreative, Autoplay];
      return modules
    }
  },

  async created() {
    this.getBanners()
    this.getPastEvents()
    this.getUpcomingProducts();
    this.getConcertProducts({
      page: 1,
      pageSize: this.pageSize,
    });
    this.getAttractionProducs({
      page: 1,
      pageSize: this.pageSize,
    });
    this.getHealingProduct({
      page: 1,
      pageSize: this.pageSize,
    });
    this.getRaceProducts({
      page: 1,
      pageSize: this.pageSize,
    });

    await this.axios
      .get("product/own/city", {
        params: {
          category: CATEGORY.HEALING,
        },
      })
      .then((response) => {
        const city = response.data.response.cities.map((city) =>
          city.locationcity_name
            .toLowerCase()
            .replace(/\b\w/g, (s) => s.toUpperCase())
        );
        this.tags.push(...city);
      })
      .catch((error) => {
        console.log(error);
      });

    this.scrollTop();
  },

  data() {
    return {
      newsLetter: {
        rules: [
          (v) => {
            return isEmail(v) || "Tolong masukkan alamat email yang benar";
          },
        ],
        email: "",
        loading: false,
      },

      url: {
        web_seller: process.env.VUE_APP_WEB_SELLER_URL,
      },
      settings: {
        snapAlign: "center",
      },
      model: null,
      startDate: null,
      endDate: null,
      name: null,
      slides: [],
      slideLoading: false,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 50,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      tags: ["Semua"],
      sellerBenefit: () => {
        const contents = [];
        var i = 0;
        while (i < 6) {
          const key = `home.info-3.content[${i}]`;
          const message = this.$t(key);
          if (message == key) break;

          contents.push(message);
          i++;
        }

        return contents;
      },
      sure: [
        {
          title: this.$t("home.info-1.content[0].title"),
          subtitle: this.$t("home.info-1.content[0].subtitle"),
          src: NotSure1,
        },
        {
          title: this.$t("home.info-1.content[1].title"),
          subtitle: this.$t("home.info-1.content[1].subtitle"),
          src: NotSure2,
        },
        {
          title: this.$t("home.info-1.content[2].title"),
          subtitle: this.$t("home.info-1.content[2].subtitle"),
          src: NotSure3,
        },
      ],
      selection: 0,
      pageSize: 10,
      products: {
        loading: true,
        data: [],
      },
      newProducts: {
        loading: true,
        data: [],
      },
      healingProducts: {
        loading: true,
        error: false,
        data: [],
      },
      concertProducts: {
        loading: true,
        data: [],
        count: 0,
      },
      attractionProducts: {
        loading: true,
        data: [],
        count: 0,
      },
      upcomingProducts: {
        loading: true,
        data: [],
        count: 0,
      },
      pastEvents: {
        loading: true,
        data: [],
        count: 0,
      },
      workshopProducts: {
        loading: true,
        data: [],
      },
      raceProducts: {
        loading: true,
        data: [],
        count: 0,
      },
      snackbar: {
        status: false,
        text: null,
      },
    };
  },

  beforeRouteLeave() {
    this.scrollTop();
  },

  async mounted() {
    if (
      !this.products.loading &&
      !this.newProducts.loading &&
      !this.concertProducts.loading &&
      !this.attractionProducts.loading &&
      !this.upcomingProducts.loading &&
      !this.workshopProducts.loading &&
      !this.raceProducts.loading &&
      !this.pastEvents.loading
    ) {
      this.scrollTop();
    }
  },

  methods: {
    getBanners() {
      this.slideLoading = true
      this.axios.get(`s3/banner`)
        .then((response) => {
          this.slides = response.data.response
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.slideLoading = false
        })
    },

    subscribeNewsLetter(email, isValid) {
      this.newsLetter.loading = true;
      if (isValid.value) {
        this.axios
          .post("/addon/newsletter/subs/join", {
            user_email: email,
          })
          .then(() => {
            this.showSnackbar(this.$t("home.newsletter.register.success"));
            this.newsLetter.email = "";
          })
          .catch((error) => {
            switch (error?.response?.status) {
              case 421: {
                this.showSnackbar(
                  this.$t("home.newsletter.register.already-register")
                );
                break;
              }
              case 420: {
                this.showSnackbar(
                  this.$t("home.newsletter.register.user-not-found", {
                    email: email,
                  })
                );
                break;
              }
              default: {
                this.showSnackbar(
                  this.$t("home.newsletter.register.service-error")
                );
              }
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.newsLetter.loading = false;
            }, 2000);
          });
      } else {
        setTimeout(() => {
          this.showSnackbar(this.$t("home.newsletter.register.not-valid"));
          this.newsLetter.loading = false;
        }, 2000);
      }
    },
    goToURL(url) {
      window.open(url, "_blank");
    },

    async getPastEvents() {
      this.pastEvents.loading = true;
      await this.axios
        .get("product/expired", {
          params: {
            order: "desc",
          },
        })
        .then((response) => {
          const data = response?.data.response || [];
          const products = data.products;

          const soldOut = products.filter((product) => product.quantity <= 0);
          const ready = products.filter((product) => product.quantity > 0);

          this.pastEvents.data = [...ready, ...soldOut];
          this.pastEvents.count = data.count;
          this.pastEvents.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.pastEvents.loading = false));
    },

    async getUpcomingProducts() {
      this.upcomingProducts.loading = true;
      await this.axios
        .get("product/upcoming", {
          params: {
            order: "desc",
          },
        })
        .then((response) => {
          const data = response?.data.response || [];
          const products = data.products;

          const soldOut = products.filter((product) => product.quantity <= 0);
          const ready = products.filter((product) => product.quantity > 0);

          this.upcomingProducts.data = [...ready, ...soldOut];
          this.upcomingProducts.count = data.count;
          this.upcomingProducts.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.upcomingProducts.loading = false));
    },

    async getConcertProducts({ page, pageSize }) {
      this.concertProducts.loading = true;

      try {
        const data = await this.getProducts({
          page: page,
          pageSize: pageSize,
          category: CATEGORY.KONSER,
          sortBy: "event_start",
          order: "desc",
        });

        this.concertProducts.data = data.products;
        this.concertProducts.count = data.count;
      } catch {
        this.concertProducts.error = true;
      } finally {
        this.concertProducts.loading = false;
      }
    },

    async getHealingProduct({ page, pageSize, city }) {
      this.healingProducts.loading = true;

      try {
        const data = await this.getProducts({
          page: page,
          pageSize: pageSize,
          city: city == "Semua" ? null : city,
          category: CATEGORY.HEALING,
          order: "desc",
        });

        this.healingProducts.data = data.products;
        this.healingProducts.count = data.count;
      } catch {
        this.healingProducts.error = true;
      } finally {
        this.healingProducts.loading = false;
      }
    },

    async getAttractionProducs({ page, pageSize }) {
      this.attractionProducts.loading = true;

      try {
        const data = await this.getProducts({
          page: page,
          pageSize: pageSize,
          category: CATEGORY.TAMAN_HIBURAN,
          sortBy: "event_start",
          order: "desc",
        });

        this.attractionProducts.data = data.products;
        this.attractionProducts.count = data.count;
      } catch {
        this.attractionProducts.error = true;
      } finally {
        this.attractionProducts.loading = false;
      }
    },

    async getRaceProducts({ page, pageSize }) {
      this.raceProducts.loading = true;

      try {
        const data = await this.getProducts({
          page: page,
          pageSize: pageSize,
          category: CATEGORY.RACE,
          order: "desc",
        });

        this.raceProducts.data = data.products;
        this.raceProducts.count = data.count;
      } catch {
        this.raceProducts.error = true;
      } finally {
        this.raceProducts.loading = false;
      }
    },

    async getProducts({ page, pageSize, city, category, sortBy, order }) {
      const response = await this.axios.get("product", {
        params: {
          page: page,
          pageSize: pageSize,
          city: city,
          category: category,
          sortBy: sortBy,
          order: order,
        },
      });

      const data = response.data.response;
      const products = data.products;
      const soldOut = products.filter((product) => product.quantity <= 0);
      const ready = products.filter((product) => product.quantity > 0);

      return {
        products: [...ready, ...soldOut],
        count: data.count,
      };
    },

    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD-MM-YYYY");
    },

    kFormatter(number) {
      return Math.abs(number) > 999
        ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + "k"
        : Math.sign(number) * Math.abs(number);
    },

    ...mapActions(["getDetail"]),

    toDetail(id) {
      this.$router.push(`/detail/${id}`);
    },

    showSnackbar(text) {
      this.snackbar.status = true;
      this.snackbar.text = text;
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  padding: 0
}

@media (max-width: 768px) {
  .swiper-slide-active {
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 75%;
  min-width: 75%;
  align-self: center;
  height: 100%;
  margin: 0 5px;
}

.swiper-pagination {
  bottom: 10px;
}

.swiper-slide-active {
  width: 80%;
  min-width: 80%;
  margin: 0;
}

.swiper-slide .card-carousel .img-carousel {
  display: block;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  content: none;
}

.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next {
  opacity: 1;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: none;
  font-size: 0;
  font-variant: normal;
}

.swiper-button-custom {
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.bg-color {
  background-color: #f8f8f8;
}

.bg-benefit {
  background-color: #f2f0f4;
}

:deep(.v-input__append) {
  padding: 0;
  align-items: center;
}
</style>
